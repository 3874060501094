import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { differenceInDays, format } from 'date-fns';

import {
  generateMonthsData,
  getDefaultMonth,
} from '@components/listing/listingCalendar/helpers';
import {
  days,
  defaultDateRange,
  monthNames,
} from '@components/listing/listingCalendar/constants';
import Button from '@components/_shared/button';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import {
  CalendarMonth as CalendarMonthType,
  DatesRange,
  FuzzyDatesOffset,
} from '@utils/types/calendar';
import { ArrowRightTan, ChevronLeft, ChevronRight } from '@assets/icons';
import { RangePickerMonth } from './month';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';
import { useClickOutside } from '@utils/hooks/useClickOutside';

import styles from './styles.module.scss';

const fuzzyDatesOffsetOptions = [
  { label: 'Exact Dates', value: 0 },
  { label: '±1 day', value: 1 },
  { label: '±3 day', value: 3 },
  { label: '±7 day', value: 7 },
  { label: '±10 day', value: 10 },
];

type Props = {
  onRangeSelect: (range: DatesRange) => void;
  defaultFuzzyDatesOffset?: FuzzyDatesOffset;
  onFuzzyDatesOffsetChange?: (offset: FuzzyDatesOffset) => void;
  onFuzzyDatesOffsetApply?: (offset: FuzzyDatesOffset) => void;
  value?: DatesRange;
  noControls?: boolean;
  noMobile?: boolean;
  fromText?: string;
  toText?: string;
  dateFormat?: string;
  buttonHasBorder?: boolean;
  rangePickerContainerStyle?: string;
} & BaseComponentProps;

export function RangePicker(props: Props) {
  const {
    containerStyle,
    onRangeSelect,
    defaultFuzzyDatesOffset,
    onFuzzyDatesOffsetChange,
    onFuzzyDatesOffsetApply,
    value,
    noControls,
    noMobile,
    fromText = 'Check-In',
    toText = 'Check-Out',
    dateFormat = 'MMM dd',
    buttonHasBorder = false,
    rangePickerContainerStyle,
  } = props;

  const months = useMemo(() => generateMonthsData(24), []);

  const [showCalendar, setShowCalendar] = useState(false);
  const [localFuzzyDatesOffset, setLocalFuzzyDatesOffset] = useState(
    defaultFuzzyDatesOffset
  );
  const [selectedDates, setSelectedDates] = useState<DatesRange>(
    value || defaultDateRange
  );

  useEffect(() => {
    if (
      defaultFuzzyDatesOffset &&
      (defaultFuzzyDatesOffset.from !== localFuzzyDatesOffset?.from ||
        defaultFuzzyDatesOffset.to !== localFuzzyDatesOffset?.to)
    ) {
      setLocalFuzzyDatesOffset(defaultFuzzyDatesOffset);
    }
  }, [defaultFuzzyDatesOffset]);

  useEffect(() => {
    if (
      value &&
      (value.from !== selectedDates.from || value.to !== selectedDates.to)
    ) {
      setSelectedDates(value);
    }
  }, [value]);

  const [currentMonth, setCurrentMonth] = useState<CalendarMonthType>(
    value ? getDefaultMonth(value, months) : months[0]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const { toggleBodyScroll, enableScroll } = useToggleBodyScroll();

  const toggleCalendar = () => {
    if (isMobile) {
      toggleBodyScroll();
    }
    setShowCalendar((showCalendar) => !showCalendar);
  };

  const closeCalendar = () => {
    enableScroll();
    setShowCalendar(false);
  };

  useClickOutside(containerRef, closeCalendar);

  const { currentMonthIndex, nextMonthIndex } = useMemo(() => {
    const currentMonthIndex = _findIndex(
      months,
      (v) =>
        _isEqual(v.month, currentMonth.month) &&
        _isEqual(v.year, currentMonth.year)
    );
    const nextMonthIndex = Math.min(currentMonthIndex + 1, months.length - 1);
    return { currentMonthIndex, nextMonthIndex };
  }, [currentMonth, months]);

  const nextMonth = useMemo(
    () => months[nextMonthIndex],
    [months, nextMonthIndex]
  );

  const clearDates = () => {
    setSelectedDates(defaultDateRange);
    onRangeSelect(defaultDateRange);
  };

  const selectDate = (date: Date) => {
    let newDates: DatesRange;
    if (!!selectedDates.from) {
      if (!!selectedDates.to) {
        newDates = {
          from: date,
          to: null,
        };
      } else {
        newDates = {
          from: selectedDates.from,
          to: date,
        };
      }
    } else {
      newDates = {
        from: date,
        to: null,
      };
    }

    if (newDates.from && newDates.to) {
      if (newDates.from.getTime() > newDates.to.getTime()) {
        newDates = {
          from: newDates.to,
          to: newDates.from,
        };
      }
    }

    setSelectedDates(newDates);

    trackEvent(
      newDates.to
        ? SegmentEventNamesEnum.CHECK_OUT_DATES_APPLIED
        : SegmentEventNamesEnum.CHECK_IN_DATES_APPLIED,
      {
        PageName:
          router.pathname === '/home'
            ? SegmentEventPagesEnum.HOME_PAGE
            : SegmentEventPagesEnum.SEARCH_PAGE,
      }
    );
  };

  const goToPrevMonth = useCallback(() => {
    if (currentMonthIndex === 0) {
      return;
    }
    setCurrentMonth(months[currentMonthIndex - 1]);
  }, [currentMonthIndex, months]);

  const goToNextMonth = useCallback(() => {
    if (nextMonthIndex === months.length - 1) {
      return;
    }
    setCurrentMonth(months[nextMonthIndex]);
  }, [nextMonthIndex, months]);

  const goToMonthByMonthIndex = useCallback(
    (monthIndex: number) => {
      if (
        _isEqual(currentMonth.month, months[monthIndex].month) &&
        _isEqual(currentMonth.year, months[monthIndex].year)
      ) {
        return;
      }
      setCurrentMonth(months[monthIndex]);
    },
    [currentMonth.month, months]
  );

  useEffect(() => {
    /**
     * On desktop, we show 2 months at a time,
     * on mobile - only one
     */
    const offset = isMobile ? 0 : 1;
    if (
      selectedDates.from &&
      selectedDates.to &&
      (months[currentMonthIndex + offset].month !==
        selectedDates.to.getMonth() ||
        months[currentMonthIndex + offset].year !==
          selectedDates.to.getFullYear())
    ) {
      const selectedMonth = months.findIndex(
        (m) =>
          _isEqual(m.month, selectedDates.to?.getMonth()) &&
          _isEqual(m.year, selectedDates.to?.getFullYear())
      );
      if (selectedMonth - offset !== -1) {
        goToMonthByMonthIndex(selectedMonth - offset);
      }
    }
  }, []);

  const handleKeyboardArrows = useCallback(
    (e: KeyboardEvent) => {
      if (['ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
        e.preventDefault();
        if (['ArrowLeft'].indexOf(e.code) > -1) {
          goToPrevMonth();
        } else {
          goToNextMonth();
        }
      }
    },
    [goToNextMonth, goToPrevMonth]
  );

  const handleMouseWheel = useCallback(
    (e) => {
      /**
       * YAxis scroll - mWheelUp/mWheelDown + Shift
       */
      if (e.shiftKey) {
        if (Math.sign(e.deltaY) === 1) goToNextMonth();
        if (Math.sign(e.deltaY) === -1) goToPrevMonth();
      }

      /**
       * XAxis scroll - trackpad / touchpad
       */
      if (Math.abs(e.deltaX) !== 0) {
        if (Math.sign(e.deltaX) === 1) goToNextMonth();
        if (Math.sign(e.deltaX) === -1) goToPrevMonth();
      }
    },
    [goToNextMonth, goToPrevMonth]
  );

  const debouncedMouseWheel = useMemo(
    () => _debounce(handleMouseWheel, 100),
    [handleMouseWheel]
  );

  const onMouseWheel = useCallback(
    (e) => {
      if (e.shiftKey || e.deltaX !== 0) {
        e.preventDefault();
        e.stopPropagation();
        debouncedMouseWheel(e);
      }
    },
    [debouncedMouseWheel]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardArrows);
    if (calendarRef.current) {
      calendarRef.current.addEventListener('mousewheel', onMouseWheel, {
        passive: false,
      });
    }
    return () => {
      window.removeEventListener('keydown', handleKeyboardArrows);
      if (calendarRef.current) {
        calendarRef.current.removeEventListener('mousewheel', onMouseWheel);
      }
    };
  }, [handleKeyboardArrows, onMouseWheel]);

  const onApplyClick = () => {
    const from = selectedDates.from;
    const to = selectedDates.to;

    if (from && to) {
      onRangeSelect({ from, to });
    } else if (!from && !to) {
      onRangeSelect({ from, to });
    }

    toggleCalendar();

    if (onFuzzyDatesOffsetApply && localFuzzyDatesOffset) {
      onFuzzyDatesOffsetApply(localFuzzyDatesOffset);
    }
  };

  useEffect(() => {
    if (noControls) {
      onRangeSelect(selectedDates);
    }
  }, [selectedDates.from, selectedDates.to, noControls]);

  return (
    <div
      className={clsx([styles.container, containerStyle])}
      ref={containerRef}
    >
      {isMobile && !noMobile ? (
        <div className={styles.button} onClick={toggleCalendar}>
          <div className={styles.label}>
            <div className={clsx(buttonHasBorder && styles.borderButton)}>
              {fromText}
            </div>
            &nbsp;-&nbsp;
            <div className={clsx(buttonHasBorder && styles.borderButton)}>
              {toText}
            </div>
          </div>
          {selectedDates.from && selectedDates.to ? (
            <div className={styles.dates}>
              {format(selectedDates.from, dateFormat)}
              &nbsp;-&nbsp;
              {format(selectedDates.to, dateFormat)}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              {differenceInDays(selectedDates.to, selectedDates.from)}
              &nbsp;days
            </div>
          ) : (
            <span className={styles.placeholder}>Select dates</span>
          )}
        </div>
      ) : (
        <div className={styles.button} onClick={toggleCalendar}>
          <div
            className={clsx([
              styles.dateButton,
              buttonHasBorder && styles.borderButton,
              showCalendar && styles.active,
            ])}
          >
            {selectedDates.from
              ? format(selectedDates.from, dateFormat)
              : fromText}
          </div>

          <ArrowRightTan />

          <div
            className={clsx(
              styles.dateButton,
              buttonHasBorder && styles.borderButton,
              showCalendar && styles.active
            )}
          >
            {selectedDates.to ? format(selectedDates.to, dateFormat) : toText}
          </div>
        </div>
      )}

      {showCalendar && (
        <section
          className={clsx([styles.rangePicker, rangePickerContainerStyle])}
          ref={calendarRef}
        >
          <div className={styles.header}>
            <div onClick={goToPrevMonth} className={styles.header__arrow}>
              <ChevronLeft />
            </div>
            <div onClick={goToNextMonth} className={styles.header__arrow}>
              <ChevronRight />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.monthWrapper}>
              <div className={styles.monthName}>
                {monthNames[currentMonth.month]} {currentMonth.year}
              </div>

              <div className={styles.week}>
                {days.map((day, idx) => (
                  <div key={idx} className={styles.day}>
                    {day}
                  </div>
                ))}
              </div>

              <div className={styles.list} style={{ height: 'max-content' }}>
                <RangePickerMonth
                  month={currentMonth}
                  selectedDates={selectedDates}
                  onDateSelect={selectDate}
                  fuzzyDatesOffset={localFuzzyDatesOffset}
                />
              </div>
            </div>

            {!isMobile && (
              <div className={styles.monthWrapper}>
                <div className={styles.monthName}>
                  {monthNames[nextMonth.month]} {nextMonth.year}
                </div>

                <div className={styles.week}>
                  {days.map((day, idx) => (
                    <div key={idx} className={styles.day}>
                      {day}
                    </div>
                  ))}
                </div>

                <div className={styles.list} style={{ height: 'max-content' }}>
                  <RangePickerMonth
                    month={nextMonth}
                    selectedDates={selectedDates}
                    onDateSelect={selectDate}
                    fuzzyDatesOffset={localFuzzyDatesOffset}
                  />
                </div>
              </div>
            )}
          </div>

          {!!localFuzzyDatesOffset &&
            (!!onFuzzyDatesOffsetApply || !!onFuzzyDatesOffsetChange) && (
              <div className={styles.fuzzyDates}>
                <div className={styles.fuzzyDates__row}>
                  <div className={styles.fuzzyDates__label}>Check-In</div>

                  {fuzzyDatesOffsetOptions.map((option) => (
                    <div
                      key={option.value}
                      className={clsx([
                        styles.fuzzyDates__option,
                        localFuzzyDatesOffset.from === option.value &&
                          styles.fuzzyDates__option__selected,
                      ])}
                      onClick={() => {
                        const newOffset = {
                          from: option.value,
                          to: localFuzzyDatesOffset.to,
                        };
                        setLocalFuzzyDatesOffset(newOffset);
                        if (onFuzzyDatesOffsetChange) {
                          onFuzzyDatesOffsetChange(newOffset);
                        }
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>

                <div className={styles.fuzzyDates__row}>
                  <div className={styles.fuzzyDates__label}>Check-Out</div>

                  {fuzzyDatesOffsetOptions.map((option) => (
                    <div
                      key={option.value}
                      className={clsx([
                        styles.fuzzyDates__option,
                        localFuzzyDatesOffset.to === option.value &&
                          styles.fuzzyDates__option__selected,
                      ])}
                      onClick={() => {
                        const newOffset = {
                          from: localFuzzyDatesOffset.from,
                          to: option.value,
                        };
                        setLocalFuzzyDatesOffset(newOffset);
                        if (onFuzzyDatesOffsetChange) {
                          onFuzzyDatesOffsetChange(newOffset);
                        }
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}

          {!noControls && (
            <div className={styles.footer}>
              <Button
                onClick={clearDates}
                containerStyle={styles.footer__clearButton}
                variant="darkOutlined"
              >
                Clear Dates
              </Button>

              <Button
                onClick={onApplyClick}
                disabled={!selectedDates.to || !selectedDates.from}
                containerStyle={styles.footer__actionButton}
                variant="blueContained"
              >
                Apply
              </Button>
            </div>
          )}
        </section>
      )}
    </div>
  );
}
