import React from 'react';
import Head from 'next/head';

type MetHeadType = {
  title: string;
  description: string;
  ogImage?: string;
  canonical?: string;
  noIndex?: boolean;
};

const MetaHead = (props: MetHeadType) => (
  <Head>
    <title>{props.title}</title>
    <meta name="description" content={props.description} key="description" />
    {props.canonical && <link rel="canonical" href={props.canonical} />}
    {props.ogImage && <meta property="og:image" content={props.ogImage} />}
    {props.noIndex && <meta name="robots" content="noindex" />}
  </Head>
);

export default MetaHead;
