import { DatesRange } from '@utils/types/calendar';

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const defaultDateRange: DatesRange = {
  from: null,
  to: null,
};

const maxDayGapBetweenReservations = 7;

export { days, monthNames, defaultDateRange, maxDayGapBetweenReservations };
